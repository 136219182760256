import { get, del, post, put } from "./apiHelper";
import { flattenFilters } from "./utilHelper";

const getEntityList = (url, params) => get(url, { params: flattenFilters(params) });

// User

export const getAuthUser = () => get('/me');

export const getMyProfile = () => get('/me/notary-profile');

export const goIdle = () => put('/me/idle');

export const goActive = () => put('/me/active');

// Profile

export const agreeToProfileTerms = () => put('/notary/profile/terms');

export const saveMyProfileBilling = data => put('/notary/profile/billing', data);

export const saveMyProfileShipping = data => put('/notary/profile/shipping', data);

export const saveMyProfileContact = data => put('/notary/profile/contact', data);

export const saveMyProfileCredentials = data => put('/notary/profile/credentials', data);

export const saveMyProfileExperience = data => put('/notary/profile/experience', data);

export const saveMyProfileArea = data => put('/notary/profile/area', data);

export const saveMyProfileShippingArea = data => put('/notary/profile/shipping-area', data);

// Credential Doc

export const getCredentials = id => get(`/notary/${id}/credentials`);

export const uploadCredential = data => post(`/notary/profile/credential`, data);

export const deleteCredential = id => del(`/notary-credential/${id}`);

// Notary Bids

export const getMyNotaryBids = params => getEntityList('/notary-bids/my', params);

export const getNotaryBid = id => get(`/notary-bid/${id}`);

export const acceptNotaryBid = (id, data) => put(`/notary-bid/${id}/accept`, data);

export const declineNotaryBid = (id, data) => put(`/notary-bid/${id}/decline`, data);

// Notification

export const getNotificationDt = params => getEntityList('/notifications', params);

export const readManyNotifications = data => put('/notifications/read', data);

export const unreadManyNotifications = data => put('/notifications/unread', data);

export const deleteManyNotifications = data => del('/notifications', { data });

export const getNotificationList = () => getEntityList('/notifications', { page: 1, pageSize: 10, sortBy: 'id', sortDir: 'desc', });

export const getNotification = id => get(`/notification/${id}`);

export const deleteNotification = id => del(`/notification/${id}`);

export const readNotification = id => put(`/notification/${id}/read`);

export const unreadNotification = id => put(`/notification/${id}/unread`);

// Accounting

export const getStatements = params => getEntityList('/statements/notary', params);

export const getStatement = id => get(`/statement/${id}`);

// Messages

export const getMessages = params => getEntityList('/messages', params);

export const createMessage = (data, config) => post('/message', data, config);

// Dashboard
export const getStatistics = unit => get(`/dashboard/notary/leads-stats/${unit}`);

export const getChats = () => get('/dashboard/notary/recent-chats');

export const getInProgressOrders = params => get(`/dashboard/notary/in-progress-orders?limit=${params}`);

// Orders

export const getOrders = params => getEntityList('/notary/my-orders', params);

export const getOrder = id => get(`/notary/order/${id}`);

export const addOrderDoc = (data, orderId) => post(`/order/${orderId}/doc/add`, data);

export const getOrderMessages = (orderId, channelId, params) => getEntityList(`/order/${orderId}/messages/${channelId}`, params);

export const getAllOrderMessages = (orderId, params) => getEntityList(`/order/${orderId}/messages`, params);

export const getChatMembers = (orderId, channelId) => get(`/order/${orderId}/messages/${channelId}/participants`);

export const createOrderMeeting = (orderId, role) => post(`/order/${orderId}/meet/${role}`);

// OrderDoc

export const deleteOrderDoc = id => del(`/order-doc/${id}`);

export const deleteAdditionalDocs = id => del(`/notary/order/${id}/additional-notary-docs`);

export const uploadOrderDocPage = (data, id, page, config) => post(`/order-doc/${id}/page/${page}`, data, config);

export const clearOrderDocPage = (id, page) => put(`/order-doc/${id}/page/${page}/clear`);

export const getInkSignOrderDocs = orderId => get(`/order/${orderId}/docs/ink-sign`);

export const uploadOrderDocPdf = (id, data) => put(`/order-doc/${id}/pdf/upload`, data);

export const deleteOrderDocPdf = id => del(`/order-doc/${id}/pdf/delete`);

// Order Notary

export const docsReceived = id => put(`/notary/order/${id}/docs-received`);

export const setAppointment = (id, data) => put(`/notary/order/${id}/set-appointment`, data);

export const confirmAppointment = id => put(`/notary/order/${id}/confirm-appointment`);

export const prepareSigning = id => put(`/notary/order/${id}/docs-printed`);

export const confirmSigning = (id, data) => put(`/notary/order/${id}/docs-uploaded`, data);

export const returnDocToDealer = (id, orderDocId) => put(`/notary/order/${id}/doc/${orderDocId}/sent`);

export const returnAllDocsToDealer = id => put(`/notary/order/${id}/docs-sent`);

export const completeOrder = id => put(`/notary/order/${id}/complete-order`);

export const printDocuments = (id, config) => get(`notary/order/${id}/docs/print-all`, config)

// Fedex and UPS

export const getOrderShipping = id => get(`/order/${id}/track/shipping`);

// Order Fees

export const getOrderFees = id => get(`/order/${id}/fees`);

// Knowledgebase

export const getServicedeskArticles = (searchTerm) => get(`/knowledgebase/articles?query=${searchTerm}`);