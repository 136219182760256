import React, { useState, useCallback } from "react";
import { Button, Input, Row, Col, Label } from "reactstrap";
import DatePicker from "components/Shared/DatePicker";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { confirmAppointment, setAppointment } from "helpers/backendHelper";
import { getOrder } from "store/actions";
import { showError, showSuccess } from "helpers/utilHelper";
import { formatTimestamp, formats } from "helpers/dateHelper";
import OrderNotary from "model/orderNotary";
import { useSocketOn } from "hooks/socket";
import socketEvent from "constants/socketEvent";
import Confirmation from "components/Shared/Confirmation";
import ContactBtn from 'components/CommonForBoth/ContactBtn';
import Order from "model/order";
import InstructionsAccordeon from "components/Shared/InstructionsAccorderon";
import { BrowserView, MobileView } from "react-device-detect";
import supportIcon from "assets/images/instructions/support.svg";
import notificationIcon from "assets/images/instructions/notification.svg";
import pinIcon from "assets/images/instructions/pin.svg";
import idIcon from "assets/images/instructions/id.svg";
import documentsIcon from "assets/images/instructions/documents.svg";

const instructions = [{
  image: supportIcon,
  description: 'In order to establish the meeting, please contact the customer as soon as possible and when everyhting is discussed, please add the details here.'
},
{
  image: notificationIcon,
  description: 'Once you enter the date and time, the customer will get a notification to confirm.'
},
{
  image: pinIcon,
  description: 'If, for whatever reason, the customer/notary requests to change the sigining location from the above sigining addresss it is required to advice Maverick ASAP.'
},
{
  image: idIcon,
  description: 'Please inform the customer that they will need to bring their two forms of ID to the sigining and you cannot proceed without them.'
},
{
  image: documentsIcon,
  description: 'Please inform the customer about the items you will be collecting at the siging.'
},
];

const StepSetAppointment = () => {

  let { id } = useParams();
  id = parseInt(id);

  // redux hook that dispatches actions
  const dispatch = useDispatch();

  const order = useSelector(state => state.Order.Single.order);

  const [meetingTs, setMeetingTs] = useState(order.notaryMeetingTs || "");
  const [meetingAddress, setMeetingAddress] = useState(order.notaryMeetingAddress || "");
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [isConfirmationMeetingVisible, setIsConfirmationMeetingVisible] = useState(false);

  const isNotCurrentStep = !(order.orderNotaryStatus === OrderNotary.STATUS_DOCS_RECEIVED || order.orderNotaryStatus === OrderNotary.STATUS_APPOINTMENT_DECLINED);

  const handleSetAppointment = () => {
    setAppointment(order.orderNotaryId, { meetingTs, meetingAddress })
      .then(() => {
        showSuccess("Step completed");
        refreshOrder();
      })
      .catch(() => {
        showError("Unable to complete step");
      });
  };

  const handleConfirmForCustomer = () => {
    confirmAppointment(order.orderNotaryId, { meetingTs, meetingAddress })
      .then(() => {
        showSuccess("Appointment confirmed");
        refreshOrder();
      })
      .catch(() => {
        showError("Unable to confirm appointment");
      });
  };

  const displayButtonText = () => {
    if (order.orderNotaryStatus === OrderNotary.STATUS_APPOINTMENT_SET) return 'Waiting for customer response';
    return 'Confirm'
  }

  const displayConfirmClientBtn = () => order.orderNotaryStatus === OrderNotary.STATUS_APPOINTMENT_SET;

  /********** SOCKET **********/

  const onAppoimentStatusReceived = useCallback(() => {
    // both dispatch and redux actions are stable functions
    // so no need to pass as dependencies
    dispatch(getOrder(id));
  }, []);

  // listen for changes on notifications
  useSocketOn(socketEvent.appointmentStatusChanged, onAppoimentStatusReceived);

  const refreshOrder = () => dispatch(getOrder(id));

  const isReadOnly = () => order.status == Order.STATUS_CANCELLED || order.orderNotaryStatus == OrderNotary.STATUS_ORDER_COMPLETE;

  return (
    <React.Fragment>
      <Row>
        {/* Mobile Instructions */}
        <MobileView>
          <InstructionsAccordeon instructions={instructions} />
        </MobileView>
        <Col>
          <ContactBtn />
        </Col>
        <Col xs={12} className="mb-2">
          <Label className="col-sm-4 col-form-label">Meeting Date</Label>
          <DatePicker
            enableTime
            value={meetingTs}
            onChange={selected => setMeetingTs(selected)}
            minDate={order.notaryMeetingTs === null ? 'today' : order.notaryMeetingTs}
            placeholder={order.notaryMeetingTs === null ? 'Pick a date' : formatTimestamp(order.notaryMeetingTs, formats.SIGNATURE_DATE)}
            disabled={isReadOnly()}
          />
        </Col>
        <Col xs={12} className="mb-2">
          <Label className="col-sm-4 col-form-label">Meeting Address</Label>
          <Input
            type="text"
            placeholder="ex. 2273 Muldrow Dr"
            onChange={e => setMeetingAddress(e.target.value)}
            value={meetingAddress}
            disabled={isReadOnly()}
          />
        </Col>

        {displayConfirmClientBtn() &&
          <Col xs={12} className="mb-2 mt-3">
            <Button
              color="primary"
              className="mav-card-action-btn"
              onClick={() => setIsConfirmationVisible(true)}
              disabled={!isNotCurrentStep || isReadOnly()}
            >
              Confirm for customer
            </Button>
          </Col>
        }
      </Row>

      <div className="divider-faded divider-sm" />

      {/* Desktop Instructions */}
      <BrowserView>
        <h6>Action: </h6>
        <ul>
          <li>
            In order to establish the meeting, please contact the customer as soon as possible and when everything is discussed, please add the details here.
          </li>
          <li>
            Once you enter the date and time, the customer will get a notification to confirm.
          </li>
          <li>
            If, for whatever reason, the customer/notary requests to change the sigining location from the above sigining address it is required to advice Maverick ASAP.
          </li>
        </ul>
      </BrowserView>

      <div className="text-end">
        <Button
          color="primary"
          className="mav-card-action-btn"
          onClick={() => setIsConfirmationMeetingVisible(true)}
          disabled={!(meetingTs && meetingAddress) || order.orderNotaryStatus === OrderNotary.STATUS_APPOINTMENT_SET || isReadOnly()}
        >
          {displayButtonText()}
        </Button>
      </div>
      {isConfirmationVisible && <Confirmation
        confirmBtnText="Confirm"
        onConfirm={() => { handleConfirmForCustomer(); setIsConfirmationVisible(false) }}
        onCancel={() => setIsConfirmationVisible(false)}>
        <span style={{ color: '#556EE6' }}>Are you sure you want to confirm appointment for customer?</span>
      </Confirmation>
      }
      {isConfirmationMeetingVisible && <Confirmation
        confirmBtnText="Confirm"
        cancelBtnText="Close"
        onConfirm={() => { handleSetAppointment(); setIsConfirmationMeetingVisible(false) }}
        onCancel={() => setIsConfirmationMeetingVisible(false)}>
        <span style={{ color: '#556EE6' }}>Are you sure you want to confirm appointment?</span>
      </Confirmation>
      }
    </React.Fragment >
  )
};

export default StepSetAppointment;