import React, { useEffect, useState } from "react";
import {Card, CardBody, Alert, CardTitle, Button, CardFooter} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Link } from "react-router-dom";
import { route, routes } from "helpers/routeHelper";
import SpinnerChase from "components/Shared/SpinnerChase";
import OrderNotary from "model/orderNotary";
import {getInProgressOrders} from "../../../../helpers/backendHelper";
import {formats, formatTimestamp} from "../../../../helpers/dateHelper";


const WIPOrdersDesktop = () => {

  /********** STATE **********/
  const [orders, setOrders] = useState([]);
  const [ordersError, setOrdersError] = useState(false);
  const [isLoadInProgress, setIsLoadInProgress] = useState(false);

  const ordersLimit  = 10;

  /********** HANDLER **********/
  const handleMeetingTime = (timestamp, status, id) => {
     if(timestamp) return formatTimestamp(timestamp, formats.HOUR);

     if(!timestamp) {
       switch (status) {
         case OrderNotary.STATUS_ASSIGNED:
         case OrderNotary.STATUS_VIEWED:
           return '-'
         case OrderNotary.STATUS_DOCS_RECEIVED:
           return (<Link to={route(routes.view_order_flow, id)}>
             <span className="badge badge-lg rounded-pill bg-primary">Schedule</span>
           </Link>)
       }
     }
  }

  const onGetInProgressOrders = () => {
    setIsLoadInProgress(true);
    getInProgressOrders(ordersLimit)
    .then(resp => setOrders(resp.orders))
    .catch((err) => {
      setOrdersError(err);
      showError("Unable to load data");
    })
    .finally(() => {
      setIsLoadInProgress(false);
    });
  }

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    onGetInProgressOrders()
  }, []);

  return <>
    <Card className="wip-orders-card">
      <CardTitle>In Progress Orders</CardTitle>
      <CardBody className="pt-1">
        <Table className="table">
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th>Signing ID</Th>
              <Th>Customer Name</Th>
              <Th>Dealer</Th>
              <Th>Status</Th>
              <Th>Meeting Time</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {orders.length > 0 && orders.map((entry, index) => (
              <Tr key={entry.id}>
                <Td>{index + 1}</Td>
                <Td>{entry.id}</Td>
                <Td>{entry.customerName}</Td>
                <Td>{entry.dealerName}</Td>
                <Td>
                  <span className={`badge badge-lg rounded-pill w-100 bg-${OrderNotary.getStatusColor(entry.status)}`}>
                  {OrderNotary.getStatusName(entry.status)}</span>
                </Td>
                <Td>{handleMeetingTime(entry.meetingTs, entry.status, entry.id)}</Td>
                <Td>
                  <Link to={route(routes.view_order, entry.id)}><span className="badge badge-lg rounded-pill bg-primary">View Details</span></Link>
                </Td>
              </Tr>
            ))}
            {orders.length === 0 && !ordersError && !isLoadInProgress && <Tr>
              <Td className="table-empty" colSpan="7">No orders found</Td>
            </Tr>
            }
            {!!ordersError && <Tr>
              <Td className="table-empty" colSpan="7">
                <Alert color="danger" className="fade show text-center mb-0">
                  <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load orders
                </Alert>
              </Td>
            </Tr>}
            {isLoadInProgress && <Tr>
              <Td className="table-empty" colSpan="7">
                <SpinnerChase className="sm" />
              </Td>
            </Tr>}
          </Tbody>
        </Table>
      </CardBody>
      <CardFooter className="bg-transparent d-flex justify-content-end pt-0">
        <a href={route(routes.list_orders)}>
          <Button color="light" className="text-primary">
            View All <i className="bx bx-right-arrow-alt" />
          </Button>
        </a>
      </CardFooter>
    </Card>
  </>
}

export default WIPOrdersDesktop;